import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Login.vue';
import Login from './views/Login.vue';
import NewProjectField from './views/ProjectFieldNew.vue';
import UpdateProjectField from './views/ProjectFieldUpdate.vue';
import SeeProject from './views/ProjectDetails.vue';
import NewProject from './views/ProjectNew.vue';
import UpdateProject from './views/ProjectUpdate.vue';
import NewTeam from './views/TeamNew.vue';
import UpdateTeam from './views/TeamUpdate.vue';
import UserMod from './views/UserMod.vue';
import UserProductivity from './views/UserProductivity.vue';
import Validation from './views/ValidationPage.vue';
import ValidationNewLayout from './views/ValidationPageNewLayout.vue';
import ValidationNewLayoutCompare from './views/ValidationPageNewLayoutCompare.vue';
import ValidationNewLayoutMulti from './views/ValidationPageNewLayoutMulti.vue';
import ValidationMulti from './views/ValidationPageMulti.vue';
import CertValidation from './views/ValidationPageCert.vue';
import QCValidation from './views/ValidationPageQC.vue';
import QCValidationReload from './views/ValidationPageQCReload.vue';
import QCValidationMulti from './views/ValidationPageQCMulti.vue';
import QCValidationReloadMulti from './views/ValidationPageQCReloadMulti.vue';
import ValidationTest from './views/ValidationTest.vue';
import Upload from './views/UploadFile.vue';
import UploadFinalQC from './views/UploadFinalQC.vue';
import CertUpload from './views/UploadCertFile.vue';
import UpValidation from './views/UploadValidate.vue';
import UpLookup from './views/UploadLookup.vue';
import UpGolden from './views/UploadGolden.vue';
import UpPLU from './views/UploadPLU.vue';
import UpBrand from './views/UploadBrand.vue';
import UpCategory from './views/UploadCategory.vue';
import UpReccsCat from './views/UploadReccsCat.vue';
import UpUpload from './views/UploadUpdate.vue';
import Register from './views/Register.vue';
import QCErrorsReview from './views/QCErrorsReview.vue';
import QCErrorsReviewQCTeam from './views/QCErrorsReviewQCTeam.vue';
import QCAllErrors from './views/QCAllErrors.vue';
import QCAllInconsistent from './views/QCAllInconsistent.vue';
import QCTasksAvailable from './views/QCTasksAvailable.vue';
import inProductionSummary from './views/inProductionSummary.vue';
import OpenTasksSummary from './views/openTasksSummary.vue';
import inProductionChanges from './views/inProductionChanges.vue';
import importReview from './views/ImportReview.vue';
import exportReview from './views/ExportReview.vue';

import UploadSummary from './views/UploadSummary.vue';
import QCUploadReview from './views/QCUploadReview.vue';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/newproject',
      name: 'newproject',
      component: NewProject
    },
    {
      path: '/editproject/:id',
      name: 'updateproject',
      component: UpdateProject
    },
    {
      path: '/newteam',
      name: 'newteam',
      component: NewTeam
    },
    {
      path: '/editteam/:id',
      name: 'updateteam',
      component: UpdateTeam
    },
    {
      path: '/project/:id',
      name: 'seeproject',
      component: SeeProject
    },
    {
      path: '/newprojectfield/:projectid',
      name: 'NewProjectField',
      component: NewProjectField
    },
    {
      path: '/editprojectfield/:projectfieldid',
      name: 'UpdateProjectField',
      component: UpdateProjectField
    },
    {
      path: '/validation/:id',
      name: 'validation',
      component: Validation
    },
    {
      path: '/validationnewlayout/:id',
      name: 'validationnewlayout',
      component: ValidationNewLayout
    },
    {
      path: '/validationnewlayoutcompare/:id',
      name: 'validationnewlayoutcompare',
      component: ValidationNewLayoutCompare
    },
    {
      path: '/validationnewlayoutmulti/:id',
      name: 'validationnewlayoutmulti',
      component: ValidationNewLayoutMulti
    },
    {
      path: '/validationmulti/:id',
      name: 'validationmulti',
      component: ValidationMulti
    },
    {
      path: '/certvalidation',
      name: 'Certvalidation',
      component: CertValidation
    },
    {
      path: '/qc/:id',
      name: 'qcvalidation',
      component: QCValidation
    },
    {
      path: '/qcrel/:id',
      name: 'qcvalidationrelaod',
      component: QCValidationReload
    },
    {
      path: '/qcmulti/:id',
      name: 'qcvalidationmulti',
      component: QCValidationMulti
    },
    {
      path: '/qcmultirel/:id',
      name: 'qcvalidationrelaodmulti',
      component: QCValidationReloadMulti
    },
    {
      path: '/qcerrors',
      name: 'QCAllErrors',
      component: QCAllErrors
    },
    {
      path: '/qcinconsistent',
      name: 'QCAllInconsistent',
      component: QCAllInconsistent
    },
    {
      path: '/recorddrill/:id',
      name: 'ErrorsReview',
      component: QCErrorsReview
    },
    {
      path: '/recorddrillqc/:id',
      name: 'ErrorsReviewQC',
      component: QCErrorsReviewQCTeam
    },
    {
      path: '/qctasks',
      name: 'QCTasksAvailable',
      component: QCTasksAvailable
    },
    {
      path: '/testvalidation/:id',
      name: 'validationTest',
      component: ValidationTest
    },
    {
      path: '/upload/:projectid',
      name: 'upload',
      component: Upload
    },
    {
      path: '/upload_final_qc',
      name: 'upload_final_qc',
      component: UploadFinalQC
    },
    {
      path: '/certupload/:projectid',
      name: 'certupload',
      component: CertUpload
    },
    {
      path: '/upvalidation/:projectid',
      name: 'upvalidation',
      component: UpValidation
    },
    {
      path: '/uplookup/:projectid',
      name: 'uplookup',
      component: UpLookup
    },
    {
      path: '/upgolden',
      name: 'upgolden',
      component: UpGolden
    },
    {
      path: '/upplu',
      name: 'upplu',
      component: UpPLU
    },
    {
      path: '/upbrand',
      name: 'upbrand',
      component: UpBrand
    },
    {
      path: '/upcategory',
      name: 'upcategory',
      component: UpCategory
    },
    {
      path: '/upreccscat',
      name: 'upreccscat',
      component: UpReccsCat
    },
    {
      path: '/upupdate',
      name: 'upupdate',
      component: UpUpload
    },
    {
      path: '/uploadsummary',
      name: 'UploadSummary',
      component: UploadSummary
    },
    {
      path: '/home',
      component: Home
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/register',
      component: Register
    },
    {
      path: '/inProd',
      component: inProductionSummary
    },
    {
      path: '/openTasks',
      component: OpenTasksSummary
    },
    {
      path: '/inProd/edit/:id',
      component: inProductionChanges
    },
    {
      path: '/uploadsummary/:id',
      component: UploadSummary
    },
    {
      path: '/uploadqcsummary/:id',
      component: QCUploadReview
    },
    {
      path: '/user/mod/:id',
      component: UserMod
    },
    {
      path: '/mod/productivity/:id',
      component: UserProductivity
    },
    {
      path: '/import/review/:id/:prid',
      name: 'ImportReview',
      component: importReview
    },
    {
      path: '/export/review/:id',
      name: 'ExportReview',
      component: exportReview
    },
    {
      path: '/profile',
      name: 'profile',
      // lazy-loaded
      component: () => import('./views/Profile.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      // lazy-loaded
      component: () => import('./views/BoardAdmin.vue')
    },
    {
      path: '/uploadsearch',
      name: 'uploadsearch',
      // lazy-loaded
      component: () => import('./views/UploadSearch.vue')
    },
    {
      path: '/mod',
      name: 'moderator',
      // lazy-loaded
      component: () => import('./views/BoardModerator.vue')
    },
    {
      path: '/user',
      name: 'user',
      // lazy-loaded
      component: () => import('./views/BoardUser.vue')
    },
    {
      path: '/qc',
      name: 'qc',
      // lazy-loaded
      component: () => import('./views/BoardQC.vue')
    },
    {
      path: '/import/jobs',
      name: 'imoprtJobs',
      // lazy-loaded
      component: () => import('./views/JobImport.vue')
    },
    {
      path: '/import/jobscsv',
      name: 'imoprtJobsCSV',
      // lazy-loaded
      component: () => import('./views/JobImportCSV.vue')
    },
    {
      path: '/dailystats',
      name: 'dailystats',
      // lazy-loaded
      component: () => import('./views/ReportingPage.vue')
    }
  ]
});
